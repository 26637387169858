let referrer

function debounce(func, wait) {
    let timeout;
    return function(...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}

/**
 * Get file base name
 * @param f
 * @return string
 */
function basename(f) {
    if (!f || !f.length) {
        return ''
    }
    return f.replace(/(.*)[\/\\]/g, '')
}

/**
 * Display current popup element
 * @param popup
 */
function popup(popup) {
    fadeOut('.popup')

    const el = $(popup)
    const diff = window.innerHeight - el.outerHeight()
    const topOffset = (diff > 0 ? diff / 2 : 10) + $(document).scrollTop()

    el.css({
        'margin-left': '-' + (el.outerWidth() / 2) + 'px',
        'top': topOffset + 'px'
    })

    fadeIn('.overlay', popup)
}

/**
 * Get form from ajax request and display it
 * @param url
 * @param p
 * @returns {*}
 */
function ajaxPopup(url, p) {
    if ($('.popup' + p).length) {
        return popup(p)
    }
    $.get(url, function (result) {
        $('.popups').append(result)
        return popup(p)
    })
    return false
}

/**
 * Show auth form
 * @param obj
 * @returns {*}
 */
function auth(obj) {
    if (obj) {
        if ($(obj).prop('href')) {
            referrer = $(obj).prop('href')
        }
    }
    return ajaxPopup('/auth/form/', '#authorization')
}

/**
 * Close popup
 * @param obj
 * @returns {*}
 */
function closePopup(obj) {
    const p = $(obj).closest('.popup')
    if (!p.length) {
        return false
    }
    return fadeOut('.overlay', p)
}

/**
 * Remove captions from elements in form
 * @param form
 */
function flushCaptions(form) {
    if (!$(form).length) {
        return false
    }
    $(form).find('input,select').each(function () {
        $(this).caption(':remove')
    })
}

/**
 * Show status message
 * @param {string} m
 * @param {string} t
 */
function message(m, t) {
    $.jGrowl(m, {theme: t, life: 5000})
}

/**
 * Show error message
 * @param {string} m
 * @returns {*}
 */
function error(m) {
    return message(m, 'error')
}

/**
 * Show success message
 * @param {string} m
 * @returns {*}
 */
function success(m) {
    return message(m, 'success')
}

/**
 * Create and show dialog window
 * @param content
 * @returns {*}
 */
function dialog(content) {
    var key = 'd' + Math.floor(Math.random() * 1000),
        id = '#' + key,
        tpl = '<div class="popup dialog" id="' + key + '">content<a href="javascript:void(0);" class="close" title="close" onclick="closePopup(this)"><i class="icon icon-close"></i></a></div>'.replace('content', content)
    $('.popups').append(tpl)

    return popup(id)
}

/**
 * Show concrete element
 * @param {string} el
 * @returns {*}
 */
function show(el) {
    for (let i = 0; i < arguments.length; i++) {
        if ($(arguments[i]).length) {
            $(arguments[i]).show()
        }
    }
    return false
}

/**
 * Show concrete element
 * @param {string} el
 * @returns {*}
 */
function fadeIn(el) {
    for (let i = 0; i < arguments.length; i++) {
        if ($(arguments[i]).length) {
            $(arguments[i]).fadeIn()
        }
    }
    return false
}

/**
 * Show concrete element
 * @param {string} el
 * @returns {*}
 */
function fadeOut(el) {
    for (let i = 0; i < arguments.length; i++) {
        if ($(arguments[i]).length) {
            $(arguments[i]).fadeOut()
        }
    }
    return false
}

/**
 * Hide concrete element
 * @param {string} el
 * @returns {*}
 */
function hide(el) {
    for (let i = 0; i < arguments.length; i++) {
        if ($(arguments[i]).length) {
            $(arguments[i]).hide()
        }
    }
    return false
}

/**
 * Slide element
 * @param {string } el
 * @param {int} duration
 * @returns {boolean}
 */
function slide(el, duration) {
    if ($(el).length) {
        return $(el).slideToggle(duration)
    }
    return false
}

/**
 * Duplicate element to the current place
 * @param el
 * @param place
 * @returns {*}
 */
function dpl(el, place) {
    if (!$(el).length) {
        return false
    }
    var dpl = $(el + ':last').clone().val('')
    var loc = place || $(el + ':last')
    return $(loc).after(dpl)
}


/**
 * Submit closest form
 * @param obj
 * @returns {*}
 */
function submit(obj) {
    var form = $(obj).closest('form')
    if (!$(form).length) {
        return false
    }
    return $(form).submit()
}

/**
 * Disable element
 * @param {string} obj
 */
function disable(obj) {
    if (!$(obj).length) {
        return false
    }
    return $(obj).attr('disabled', 'disabled')
}

/**
 * Enable disabled element
 * @param {string} obj
 */
function enable(obj) {
    if (!$(obj).length) {
        return false
    }
    return $(obj).removeAttr('disabled')
}

/**
 * Show or hide element
 * @param els
 */
function toggle(els) {
    for (var i = 0; i < arguments.length; i++) {
        if ($(arguments[i]).length) {
            $(arguments[i]).toggle()
        }
    }
    return false
}


/**
 * Display concrete upsell step
 * @param step
 */
function toStep(step) {
    if ($('#upsell').length) {
        $.post('/ajax/options/', $('#upsell').serialize() + '&step=' + step, function (data) {
            if (data) {
                $('.upsell').html(data)
            }
        }, 'html')
    }
}

let isSliderTransition = false

function showSelectedSlide(id) {
    if (isSliderTransition) {
        return
    }

    $('.product-slider-thumbs picture').each(function () {
        const slideId = $(this).data('slide');
        $(this).toggleClass('active-slide', slideId === id);
    })


    const nextSlide = $('#slide' + id)
    const currentSlide = $('.product-slider-items picture:visible')
    if (!nextSlide.length || nextSlide.is(currentSlide)) {
        return
    }

    isSliderTransition = true
    currentSlide.fadeOut('fast', function () {
        nextSlide.fadeIn('fast', function () {
            isSliderTransition = false
        })
    })
}

/**
 * Slide to element
 * @param {string} el
 */
function slideTo(el) {
    const $el = $(el)
    if ($el.length) {
        $('html, body').animate({
            scrollTop: $el.offset().top
        }, 1000)
    }
}

/**
 *
 * @param obj
 * @param container
 * @returns {boolean}
 */
function loadNextPage(obj, container) {
    const self = $(obj)
    const url = self.data('source')
    const page = self.data('page') || 2
    const $container = $(container)

    $.get(url, {page: page}, function (result) {
        if (!result) {
            return self.hide()
        }
        self.data('page', page + 1)
        if ($container.length) {
            $container.append(result)
        }
    })
    return false
}

function submitFormToLink(link) {
    var self = $(link),
        url = self.prop('href'),
        separator = url.indexOf('?') === -1 ? '?' : '&',
        params = self.closest('form').serialize()

    window.location.href = url + separator + params
}

/**
 * Show errors for auth form
 * @param parent
 * @param errors
 * @returns {boolean}
 */
function showAuthErrors(parent, errors) {
    var firstItem = false
    $.each(errors, function (i, obj) {
        $('input[name*=\'' + i + '\']', parent).caption(obj[0], {align: 'right', color: 'red'})
        if (firstItem === false) {
            firstItem = $('input[name*=\'' + i + '\']', parent)
        }
    })
    firstItem.focus()
    return false
}

function calcGroupsAmountTotal() {
    $('.js-amount-group').each(function (i, obj) {
        var total = 0
        $(obj).find('input').each(function () {
            if ($(this).val() > 0) {
                total += parseInt($(this).val())
            }
        })
        if (total > 0) {
            $(obj).find('.js-amount-group-caption').find('span').html(total + ' Selected <i class="icon icon-arrow_down"></i>')
        }
    })
}

function fillAddress(fillType) {
    if (address === undefined) {
        return
    }
    const shouldClear = parseInt(fillType) === 0
    for (const i in address) {
        const el = $('#address-' + i.toLowerCase())
        if (el.length) {
            el.val(shouldClear ? '' : address[i])
        }
    }
    const state = $('#address-stateid')
    if (state.length) {
        state.trigger('change')
    }
}

function getQueryParam(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

function getDevicePixelRatio() {
    if ('devicePixelRatio' in window && window.devicePixelRatio) {
        return window.devicePixelRatio
    }
    return 1
}

function getTemplateHtml(item, place = 'product') {
    const toWebP = (path) => {
        return path.replace('.png', '.webp')
    }

    let content = `<div class="${place}-templates-list-item"><a href="${item.url}" class="${place}-templates-list-item-icon js-params-link">`
    if (item.frontPng) {
        content += `<picture class="${place}-templates-list-item-front"><source srcset="${toWebP(item.frontPng)} 1x, ${toWebP(item.frontPng2x)} 2x" type="image/webp"><img src="${item.frontPng}" srcset="${item.frontPng2x} 2x" alt=""></picture>`
        if (item.backPng) {
            content += `<picture class="${place}-templates-list-item-back"><source srcset="${toWebP(item.backPng)} 1x, ${toWebP(item.backPng2x)} 2x" type="image/webp"><img src="${item.backPng}" srcset="${item.backPng2x} 2x" alt=""></picture>`
        }
    } else {
        content += `<img src="${item.icon}" srcset="${item.icon2x} 2x" width="280" height="280" alt="" style="border: none">`
    }

    content += `</a><a href="${item.url}" class="${place}-templates-list-item-label js-params-link">customize</a>`
    if (item.backPng) {
        content += `<img src="/img/templates/icon-360.svg" class="${place}-templates-list-item-flip js-flip-icon" width="33" alt="">`
    }

    return content + '</div>'
}